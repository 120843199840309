import "./App.css";
import { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Main from "./pages/Main";
import Login from "./pages/Login";
const API_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_SERVER_LOCAL
    : "";

function App() {
  const [login, setLogin] = useState(false);
  const [globals, setGlobals] = useState({});
  const [user, setUser] = useState("");
  useEffect(() => {
    async function fetchData() {
      const data = await fetch(
        `${API_URL}/globals?token=${process.env.REACT_APP_API_TOKEN}`
      );
      const info = await data.json();
      // console.log("INFO:", info);
      setGlobals(info);
    }
    fetchData();
  }, []);

  return (
    <GoogleOAuthProvider clientId={globals.OAUTH_CLIENT_ID}>
      <div className="App">
        {login ? (
          <Main globals={globals} setLogin={setLogin} user={user}/>
        ) : (
          <Login globals={globals} setLogin={setLogin} setUser={setUser}/>
        )}
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
