import React, { useEffect, useState } from "react";
import { Typography, TextField, Button, Autocomplete, Alert, Snackbar, CircularProgress } from "@mui/material";
import "./main.css";
import LogoutButton from "../components/LogoutButton";
import LoadingDialog from "../components/LoadingDialog";
import { googleLogout } from '@react-oauth/google';

import { v4 as uuidv4 } from "uuid";
import JobTable from "../components/JobTable";
const API_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_SERVER_LOCAL
    : "";

const Main = ({globals, setLogin, user}) => {
  const [groupList, setGroupList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [selectGroup, setSelectGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [typeMsg, setTypeMsg] = useState("error")
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    async function fetchData() {
    try {
        setLoadingUsers(true);
        setLoadingGroups(true);
        const res = await fetch(globals.APP_API_GROUPS);
        const posts = await res.json();
        setGroupList(
          posts.groups.map((group) => {
            return { id: uuidv4(), label: group };
          })
        );
        const resUser = await fetch(globals.APP_API_DIRECTORY);
        const users = await resUser.json();
        setUserList(
          users.users.map((user) => {
            return { id: uuidv4(), label: user };
          })
        );
        setLoadingUsers(false);
        setLoadingGroups(false);
        setTypeMsg("success")
      } catch (error) {
        setLoadingUsers(false);
        setLoadingGroups(false);
        console.log(error);
        setTypeMsg("error")
        setShowAlert(true);
        setErrorMsg("Error loading data");
      }
    }
    fetchData();
  }, [globals.APP_API_GROUPS, globals.APP_API_DIRECTORY]);

  const handleLogout = () => {
    googleLogout();
    setLogin(false);
  };

  const handleUserChange = (event, value) => {
    setSelectUser(value?.label);
  };

  const handleGroupChange = (event, value) => {
    setSelectGroup(value?.label);
  };

  const exportData = async () => {
    if (!selectUser || !selectGroup) {
      setTypeMsg("warning")
      setShowAlert(true);
      setErrorMsg("Please select user and group");
      return;
    }

    try {
      setLoading(true);
      await fetch(`${API_URL}/export?token=${process.env.REACT_APP_API_TOKEN}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "user_name":selectUser,
          "name_group":selectGroup,
          "user":user,
        }),
      })
      setTypeMsg("success")
      setShowAlert(true);
      setErrorMsg("Data exported successfully");
      setSelectUser(null);
      setSelectGroup(null);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      // console.log(error.response);
      setTypeMsg("error")
      setShowAlert(true);
      setErrorMsg("Error exporting data");
    }
    setLoading(false);
  };

  return (
    <div>
      <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }} open={showAlert} autoHideDuration={3000} onClose={()=>{setShowAlert(false)}}>
        <Alert severity={typeMsg} sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
      <LoadingDialog loading={loading} />
      <LogoutButton onLogout={handleLogout} />
      <div className="container">
        <img src="mail2groups.png" height="200px" alt="" />
        <Typography variant="body1" color="text.secondary">
          <span hidden={!loadingUsers}> 
            <CircularProgress size="1rem"/>
          </span> Users
        </Typography>
        <Autocomplete
          loading={loadingUsers}
          value={selectUser}
          disablePortal
          id="combo-users"
          options={userList}
          disableClearable
          isOptionEqualToValue={(option, value) => option.label === value}
          onChange={handleUserChange}
          renderInput={(params) => (
            <TextField required {...params} label="User" />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={uuidv4()}>
                {option.label}
              </li>
            );
          }}
        />
        <br />
        <br />
        <Typography variant="body1" color="text.secondary">
          <span hidden={!loadingGroups}> 
            <CircularProgress size="1rem"/>
          </span> Groups
        </Typography>
        <Autocomplete
          loading={loadingGroups}
          value={selectGroup}
          disablePortal
          id="combo-groups"
          options={groupList}
          disableClearable
          isOptionEqualToValue={(option, value) => option.label === value}
          onChange={handleGroupChange}
          renderInput={(params) => (
            <TextField required {...params} label="Group" />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={uuidv4()}>
                {option.label}
              </li>
            );
          }}
        />
        <br />
        <br />
        <Button
          sx={{
            height: "50px",
            width: "250px",
          }}
          variant="contained"
          onClick={exportData}
        >
          Export Data
        </Button>
        <br />
        <br />
      </div>
      <JobTable globals={globals} refresh={refresh} setRefresh={setRefresh} />
    </div>
  );
};

export default Main;
