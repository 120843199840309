//create a component to display jobs in a table
import React, { useState, useEffect } from 'react';
import { Table, TablePagination, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import dayjs from 'dayjs'
import { IconButton, CircularProgress  } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Snackbar, Alert } from "@mui/material";

const JobTable = ({refresh, setRefresh, globals}) => {

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [startAfter, setStartAfter] = useState(null); // NEXT
    const [startAt, setStartAt] = useState([]); // BACK
    const [totalRows, setTotalRows] = useState(0);
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        async function fetchData() {
            try{
                const data = await fetch(`${globals.APP_API_BD}/api/getAllJob?pageLimit=${pageLimit}`);
                const info = await data.json();
                setRows(info.data); 
                setTotalRows(info.totalDocuments);          
                setStartAfter(info.nextPageCursor);
                setStartAt([info.prevPageCursor]);
            }catch(err){
                console.log("ERROR:", err)
            }
        }
        fetchData();
    }, [refresh, pageLimit, globals.APP_API_BD]);

    const handleChangePage = async (event, newPage) => {
        let url = `${globals.APP_API_BD}/api/getAllJob?pageLimit=${pageLimit}&startAfter=${startAfter}`
        if (newPage < page) {
            startAt.pop();
            const startAtTemp = startAt[startAt.length - 1];
            if (startAtTemp === undefined) 
                return;
            url = `${globals.APP_API_BD}/api/getAllJob?pageLimit=${pageLimit}&startAt=${startAtTemp}`
        }
        try{
            const data = await fetch(url);
            const info = await data.json();
            setRows(info.data);
            setTotalRows(info.totalDocuments);  
            setStartAfter(info.nextPageCursor);
            if (newPage > page) setStartAt([...startAt, info.prevPageCursor]);
            setPage(newPage);
        }catch(err){
            console.log("ERROR:", err)
        }
    };
    const handleRefresh = async () => {
        setRefresh(!refresh);
        setPage(0);
        setOpen(true);
    }


    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={3000}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Alert severity="success" sx={{ width: "100%" }}>
                    Data reloaded successfully
                </Alert>
            </Snackbar>
            <div style={{ textAlign: "right" }}>
                <IconButton aria-label="delete" onClick={handleRefresh}>
                    <RefreshIcon />
                </IconButton>
            </div>
            <TableContainer component={Paper}>
                <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>JobId</TableCell>
                            <TableCell>Responsible</TableCell>
                            <TableCell>Timestamp</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Group</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {row.id.substring(0, 12)}
                                </TableCell>
                                {row?.responsible ? <TableCell>{row.responsible}</TableCell> : <TableCell>Unknown</TableCell>}
                                <TableCell>{dayjs(row.date_create).format('DD/MM/YYYY hh:mm a')}</TableCell>
                                <TableCell>{row.user}</TableCell>
                                <TableCell>{row.group}</TableCell>
                                {row.status === "In Progress"? <TableCell><CircularProgress size="1rem"/> {row.status}</TableCell> 
                                : <TableCell style={{color: "green"}}>{row.status}</TableCell>
                                }

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={pageLimit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={(val) => { setPage(0); setPageLimit(val.target.value);  }}
            />
        </div>
    );
}
export default JobTable;