import React from "react";
import { Button } from "@mui/material";
import { PowerSettingsNew } from "@mui/icons-material";

const LogoutButton = ({ onLogout }) => {
  return (
    <div style={{ position: "fixed", top: "10px", right: "10px" }}>
      <Button
        variant="outlined"
        startIcon={<PowerSettingsNew />}
        onClick={onLogout}
      >
        <strong>Logout</strong>
      </Button>
    </div>
  );
};

export default LogoutButton;
