import React from "react";
import { Box } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { decodeToken } from "react-jwt";

const LoginButton = ({globals, setLogin, setUser}) => {
  const handleLogin = async (credentialResponse) => {
    const info = decodeToken(credentialResponse.credential);
    const email = info.email;
    try{

      const res = await fetch(`${globals.APP_API_BD}/api/groupListUser/${email}`);
      const result = await res.json();
      console.log("result", result);
      if (!result) {
          alert("You are not authorized to use this app");
          return;
      }
      setUser(email);
      setLogin(true);
    }
    catch(err){
      console.log(err);
      alert("You are not authorized to use this app");
      return;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <img src="mail2groups.png" height="200px" alt="" />
      <br />
      <GoogleLogin
        onSuccess={handleLogin}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </Box>
  );
};

export default LoginButton;
