import React from "react";
import { Dialog, DialogContent, CircularProgress } from "@mui/material";

const LoadingDialog = ({loading}) => {
  return (
    <Dialog
      open={loading}
      fullWidth
      maxWidth="xs"
      PaperProps={{ style: { alignItems: "center", justifyContent: "center" } }}
    >
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
